import { Injectable } from '@angular/core'
import { RouteGuard } from '@src/app/core/services/route-guard.service'
import { ManageAccountPage, Page } from '@src/app/shared/typings/enum/pages'
import { logger } from '../core/util/log.util'

@Injectable({ providedIn: 'root' })
export class CanActivateAccountPage extends RouteGuard {
  override page = ManageAccountPage.account

  getRequirements(): boolean[] {
    return [this.hasAccountId(), this.hasSession()]
  }

  canActivate(): boolean {
    logger.log('Activating Account Page')

    const requirements = this.getRequirements()

    if (!requirements.every(Boolean)) {
      void this.navService.goToPage([ManageAccountPage.account, Page.login])
      return false
    }

    if (!this.purchasedSubscription()) {
      // Go to the first step in the flow after the quiz
      void this.navService.goToPage([Page.measuringDevice])
      return false
    }

    return true
  }
}
